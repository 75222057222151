<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :product-options="['oem', 'cso']"
      :headers="_headers"
      :items="list"
      show-date
      :filter-names="filterNames"
      @filterOption="onFiltersChange"
    >
      <template v-slot:total_cost="{ item }">
        <div>
          {{
            item.total_cost !== "N/A" && item.total_cost > 0
              ? $t("currency") + " " + item.total_cost
              : item.total_cost | currencyFormat(authUser)
          }}
        </div>
      </template>

      <template #description>
        <section>
          <v-row dense>
            <!-- Card 1 -->
            <v-col
              cols="12"
              md="4"
            >
              <v-card
                :loading="isLoading"
                class="dashboard-card"
              >
                <div class="cardTopBar">
                  <div class="iconBox">
                    <Icons
                      name="total-revenue"
                      class="cardIcon"
                    />
                  </div>
                  <span class="cardTitle">
                    {{ $t("revenue") | currencyFormat(authUser) }}
                  </span>
                </div>
                <v-card-text>
                  <div class="d-flex justify-space-between align-center">
                    <div class="iconWithData">
                      <div>
                        <p class="card-value hightlightCol">
                          {{ dashboardData.total_revenue }}
                        </p>
                      </div>
                    </div>
                    <div class="card-details">
                      <Icons
                        name="chart-icon"
                        class="cardIcon"
                        :class="[
                          parseInt(dashboardData.total_revenue_inc_dec) >= 0
                            ? 'increase'
                            : 'decrease',
                        ]"
                      />
                      <span class="percentText">
                        {{ dashboardData.total_revenue_inc_dec }}%
                        {{ parseInt(dashboardData.total_revenue_inc_dec) >= 0
                          ? 'Increase'
                          : 'Decrease', }}
                      </span>
                    </div>
                  </div>
                  <div class="card-graph">
                    <!-- :value="[0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0]" -->
                    <v-sparkline
                      v-if="options.dateType !== 'today'"
                      :value="totalCostGraph"
                      :gradient="['#20a390']"
                      :smooth="10 || false"
                      :padding="8"
                      height="45"
                      :line-width="2"
                      :stroke-linecap="'round'"
                      :gradient-direction="'top'"
                      :fill="false"
                      :type="'trend'"
                      :auto-line-width="false"
                      auto-draw
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Card 2 -->
            <v-col
              cols="12"
              md="4"
            >
              <v-card
                :loading="isLoading"
                class="dashboard-card"
              >
                <div class="cardTopBar">
                  <div class="iconBox">
                    <!-- <Icons
                      name="total-revenue"
                      class="cardIcon"
                    /> -->
                    <v-icon class="cardIcon">
                      mdi-account-group
                    </v-icon>
                  </div>
                  <span class="cardTitle">
                    {{ $t("total_charge_sessions") }}
                  </span>
                </div>
                <v-card-text>
                  <div class="d-flex justify-space-between align-center">
                    <div class="iconWithData">
                      <div>
                        <p class="card-value hightlightCol">
                          {{ dashboardData.total_charge_sessions }}
                        </p>
                      </div>
                    </div>
                    <div class="card-details">
                      <Icons
                        name="chart-icon"
                        class="cardIcon"
                        :class="[
                          parseInt(
                            dashboardData.charge_session_inc_dec_percentage
                          ) >= 0
                            ? 'increase'
                            : 'decrease',
                        ]"
                      />
                      <span class="percentText">
                        {{ dashboardData.charge_session_inc_dec_percentage }}%
                        {{ parseInt(
                          dashboardData.charge_session_inc_dec_percentage
                        ) >= 0
                          ? 'Increase'
                          : 'Decrease', }}
                      </span>
                    </div>
                  </div>
                  <div class="card-graph">
                    <total-charge-session-chart
                      v-if="options.dateType !== 'today'"
                      :item="dashboardData.aggregated_sessions_bar_chart"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Card 3 -->
            <v-col
              cols="12"
              md="4"
            >
              <v-card
                :loading="isLoading"
                class="dashboard-card"
              >
                <div class="cardTopBar">
                  <div class="iconBox">
                    <!-- <Icons
                      name="total-revenue"
                      class="cardIcon"
                    /> -->
                    <v-icon class="cardIcon">
                      mdi-cash
                    </v-icon>
                  </div>
                  <span class="cardTitle">
                    {{ $t("electricity_cost") | currencyFormat(authUser) }}
                  </span>
                </div>
                <v-card-text>
                  <div class="d-flex justify-space-between align-center">
                    <div class="iconWithData">
                      <p class="card-value hightlightCol">
                        <span
                          v-if="
                            !dashboardData.total_energy_consumed ||
                              dashboardData.total_energy_consumed == 'N/A'
                          "
                        >
                          {{ dashboardData.total_energy_consumed }}
                        </span>
                        <span v-else>
                          {{ dashboardData.total_energy_consumed }}
                          <span class="si-unit">
                            {{ $t("currency") | currencyFormat(authUser) }}/kWh
                          </span>
                        </span>
                      </p>
                    </div>
                    <div class="card-details invisible">
                      <Icons
                        name="chart-icon"
                        class="cardIcon increase"
                      />
                      <span class="percentText">12% Increase</span>
                    </div>
                  </div>
                  <div class="card-graph">
                    <electricity-cost-chart
                      v-if="options.dateType !== 'today'"
                      :loading="isLoading"
                      height="100"
                      no-labels
                      :items="{ ...dashboardData }"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Revenue Overview -->
            <v-col
              cols="12"
              md="12"
              class="mt-3"
            >
              <v-card
                :loading="isLoading"
                class="dashboard-card"
              >
                <div class="cardTopBar">
                  <div class="iconBox">
                    <!-- <Icons
                      name="total-revenue"
                      class="cardIcon"
                    /> -->
                    <v-icon class="cardIcon">
                      mdi-google-analytics
                    </v-icon>
                  </div>
                  <span class="cardTitle"> Revenue Overview </span>
                </div>
                <v-card-text>
                  <div class="card-graph">
                    <revenue-overview-chart :items="{ ...dashboardData }" />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Renenvue Overview -->
            <!-- User Growth -->
            <v-col
              cols="12"
              md="6"
              class="mt-3"
            >
              <v-card
                :loading="isLoading"
                class="dashboard-card"
              >
                <div class="cardTopBar">
                  <div class="iconBox">
                    <!-- <Icons
                      name="total-revenue"
                      class="cardIcon"
                    /> -->
                    <v-icon class="cardIcon">
                      mdi-account-tie
                    </v-icon>
                  </div>
                  <span class="cardTitle"> User Growth </span>
                </div>
                <v-card-text>
                  <div class="card-graph">
                    <user-growth-chart
                      :loading="isLoading"
                      :item="dashboardData.aggregated_customers_bar_chart"
                    />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Electricity Cost Breakdown -->

            <v-col
              cols="12"
              md="6"
              class="mt-3"
            >
              <v-card
                :loading="isLoading"
                class="dashboard-card"
              >
                <div class="cardTopBar">
                  <div class="iconBox">
                    <!-- <Icons
                      name="total-revenue"
                      class="cardIcon"
                    /> -->
                    <v-icon class="cardIcon">
                      mdi-lightning-bolt
                    </v-icon>
                  </div>
                  <span class="cardTitle"> Electricity Cost Breakdown </span>
                </div>
                <v-card-text>
                  <div class="card-graph">
                    <electricity-cost-chart
                      :loading="isLoading"
                      :items="{ ...dashboardData }"
                    />
                    <!-- <apexchart
                      type="donut"
                      height="290"
                      :options="dchartOptions"
                      :series="dseries"
                    /> -->
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Electricity Cost Breakdown -->

            <!-- Recent 5 List  -->
            <v-col
              v-if="options.dateType == 'today'"
              cols="12"
              class="mt-3"
            >
              <v-card
                :loading="isLoading"
                class="dashboard-card"
              >
                <div class="cardTopBar">
                  <div class="iconBox">
                    <!-- <Icons
                      name="total-revenue"
                      class="cardIcon"
                    /> -->
                    <v-icon class="cardIcon">
                      mdi-history
                    </v-icon>
                  </div>
                  <span class="cardTitle"> Recent Transactions </span>
                </div>
                <v-card-text>
                  <v-data-table
                    :headers="recentTableHeader"
                    :items="recentList"
                    :items-per-page="5"
                    hide-default-footer
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Recent 5 List  -->
            <v-col
              cols="12"
              class="mt-3 custom-card"
            >
              <div class="cardTopBar">
                <div class="iconBox">
                  <!-- <Icons
                    name="total-revenue"
                    class="cardIcon"
                  /> -->
                  <v-icon class="cardIcon">
                    mdi-arrow-decision
                  </v-icon>
                </div>
                <span class="cardTitle"> All Transactions </span>
              </div>
            </v-col>
          </v-row>
        </section>
      </template>

      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <!-- <template #actionModals>
        <div></div>
      </template> -->
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    Icons: () => import("@/components/base/icons.vue"),
    //
    TotalChargeSessionChart: () =>
      import("./utils/TotalChargeSessionChart.vue"),
    UserGrowthChart: () => import("./utils/UserGrowthChart.vue"),
    RevenueOverviewChart: () => import("./utils/RevenueOverviewChart.vue"),
    ElectricityCostChart: () => import("./utils/ElectricityCostChart.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      recentDTHeader: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("location"),
          align: "left",
          sortable: false,
          value: "location_name",
        },
        {
          text: this.$t("cost"),
          align: "left",
          sortable: false,
          value: "energy_cost",
        },
      ],
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("charge_point_id"),
          align: "left",
          sortable: true,
          value: "charge_point_id",
        },
        {
          text: this.$t("charging_location"),
          align: "left",
          sortable: false,
          value: "location_name",
        },
        {
          text: this.$t("customer_name"),
          align: "left",
          sortable: false,
          value: "customer_name",
        },
        {
          text: this.$t("charge_total_cost"), //currency
          align: "center",
          sortable: false,
          value: "total_cost",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsChargingSession/getLoading",
      meta: "csmsChargingSession/getMeta",
      list: "csmsChargingSession/getList",
      dashboardData: "csmsDashboard/getList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    recentTableHeader() {
      // computed headers for showing columns to role based
      return this.recentDTHeader.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
    recentList() {
      return this?.list.slice(0, 5);
    },

    filterNames() {
      if (this.authUser.isAdmin) {
        return [];
      } else {
        return [];
      }
    },
    totalCostGraph() {
      if (this.dashboardData?.total_cost_per_day) {
        return this.dashboardData.total_cost_per_day.map((r) => r.total_cost);
      } else {
        return [];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  mounted() {},
  beforeDestroy() {
    this.$store.commit("csmsDashboard/SET_LIST", []);
    this.$store.commit("csmsChargingSession/SET_LIST", []);

    const params = {
      name: "payment_billing",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("csmsDashboard/list", this.options);
      await this.$store.dispatch("csmsChargingSession/list", this.options);
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

$primaryColor: #23BDAA

.chargingDashboardContent
  padding-left:0
  padding-right:0

.chargingDashboardContent .row--dense > [class*=col-]
  padding: 12px !important

.dashboard-card
  position: relative
  min-height: 180px
  margin: 0
  border-radius: 7px
  transition: all 0.3s ease
  &:hover
    margin-top: -10px

  // &::before
  //   content:''
  //   box-sizing: border-box
  //   position: absolute
  //   width: 100%
  //   height: 100%
  //   left: 0
  //   top: 9
  //   z-index: 1
  //   background: #FFFFFF
  //   border: 1px solid #F3F3F3
  //   box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
  //   border-radius: 7px

  // &::after
  //   content:''
  //   position: absolute
  //   width: 100%
  //   height: 100%
  //   left: 0
  //   top: 3px
  //   background: #F9F9F9
  //   box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
  //   border-radius: 7px

  .cardTopBar
    width: 100%
    border-bottom: 1px solid #f3f3f3
    padding: 1.3rem 1.6rem 0.7rem
    margin-bottom: 1rem
    z-index: 2
    position: relative
    display: flex
    align-items: center
    flex-wrap: wrap
    min-height: 62px

  .cardTitle
    font-size: 1.45rem
    color: #000
    font-family: $poppinSemi
    display: flex

  .card-value
    font-size: 4rem
    font-family: $poppinSemi
    z-index: 2
    margin-bottom: 0 !important
    position: relative
    color: $primaryColor

    &.hightlightCol
      color: #23BDAA

    .smallValueText
      font-size: 0.875rem
      font-family: &poppinReg

  // .v-card__text
  //   display: flex
  //   flex-wrap: wrap
  //   // padding: 2rem 1.6rem 2rem
  //   align-items: center
  // .card-graph
  //   background: lime
    // svg
    //   height:

  .card-details
    z-index: 2
    position: relative
    display: flex
    flex-direction: column
    margin-left: auto

    .cardIcon
      &.increase
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #10b981

      &.decrease
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #ef4444

    span.percentText
      display: flex
      margin-top: 0.3rem
      font-size: 0.75rem
      font-family: $poppinMed

  .dateFilterCard
    display: flex
    margin-left: auto
    width:35%
    .v-input
      padding-top:0 !important
      margin-bottom: 0 !important
      margin-top:0 !important
      :deep .v-input__control
        .v-input__slot
          background: #F6F6F6
          border-radius: 6px
          padding: 0 0.8rem
          &:before
            display: none
          &:after
            display: none
          .v-text-field__slot
            input
              font-size: 0.875rem
              color: #22272E
          .v-input__append-inner
            .v-input__icon
              justify-content: flex-end
              i
                color: $primaryColor
                font-size: 1rem
  .MapLinkCard
    display: flex
    margin-left: auto
    color: #C1C7C6
    text-decoration: none
    font-family: $poppinReg
    font-size: 0.875rem
  .cardListWrap
    display: flex
    position: relative
    z-index: 2
    ul
      display: flex
      flex-wrap: wrap
      padding: 0
      margin-bottom: 1rem
      width: 100%
      overflow: hidden
      max-height: 25px
      overflow-y: auto
      li
        font-size: 1.15rem
        color: $primaryColor
        display: flex
        flex-wrap: wrap
        width: 47%
        margin-right: 3%
        margin-bottom: 0.4rem
        font-family: $poppinSemi
    &.fullWidth
      ul
        li
          width: 100%
.dashboardFilterHeader
  .headerBarWrap
    align-items: center
    padding: 6px 12px 0

  :deep .filterBtn
    display: none !important


.drawerOpen .dashboard-card .cardTitle
  font-size: 1.3rem

.drawerOpen .chargingDashboardContent .row--dense > [class*=col-]
    padding: 12px 6px !important

.drawerOpen .dashboard-card .cardTopBar
  padding: 1rem 1rem 0.7rem

.drawerOpen .dashboard-card .dateFilterCard
  width: 40%

.linkIconWrap
  margin-left: auto
  display: flex
  color: #000
  cursor: pointer
  i
    color: #000

.linkTextWrap
    display: flex
    color: #000
    cursor: pointer
    text-decoration: underline
    &:hover
      text-decoration: none

.iconWithData
  display: flex
  flex-direction: column
  .si-unit
    color: lightgray
    font-size: 14px
.iconBox
  background: #F4F4F4
  width: 45px
  height: 34px
  z-index: 1
  border-radius: 6px
  display: flex
  align-items: center
  justify-content: center
  i
    color: $primaryColor !important
  margin: 0 0.75rem 0 0
  :deep .lg span svg
    fill: $primaryColor !important
    width: 28px
    height: 28px
  :deep .charging span svg
    fill: $primaryColor !important
    width: 45px
    height: 45px
  :deep .total-revenue span svg
    fill: $primaryColor !important
    width: 24px
    height: 24px
  :deep .new-users span svg
    fill: $primaryColor !important
    width: 24px
    height: 24px
  :deep .charge-point span svg
    fill: $primaryColor !important
    width: 25px
    height: 25px
    stroke: $primaryColor
  :deep .lightning-icon span svg
    fill: $primaryColor !important
    width: 54px
    height: 54px
    margin-left: -6px
  :deep .fault-and-connectivity span svg path
    fill: $primaryColor !important
    width: 54px
    height: 54px
    stroke: $primaryColor
  :deep .locations span svg
    fill: $primaryColor !important
    width: 23px
    height: 23px
    stroke: $primaryColor
  :deep .average-time-to-charge span svg
    fill: $primaryColor !important
    width: 52px
    height: 52px
    stroke: $primaryColor

.invisible
  visibility: hidden
a
  text-decoration: none

.custom-card
  .cardTopBar
    display: flex
    align-items: center
    padding: 1rem 1rem 0.7rem
    .cardTitle
      font-size: 1.3rem
      font-family: "Poppins SemiBold", sans-serif


@media (min-width: 1080px) and (max-width: 1366px)
  .dashboard-card .cardTitle
    font-size: 1.15rem
  .dashboard-card .cardListWrap ul li
    font-size: 1rem
  .dashboard-card .card-value
    font-size: 1.4rem
  .drawerOpen .dashboard-card .cardTitle
    font-size: 1.15rem
  .drawerClose .dashboard-card .cardListWrap ul li
    font-size: 0.9375rem
  .drawerClose .dashboard-card .card-value
    font-size: 1.15rem
</style>
